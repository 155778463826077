import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg  xmlns="http://www.w3.org/2000/svg" width="64" height="64"><path d="M57.143 32h-16v4.57h10.97c-1.6 6.857-6.63 12.57-13.257 14.857L12.57 25.143c2.743-8 10.514-13.714 19.43-13.714 6.857 0 13.03 3.43 16.914 8.686l3.43-2.97C47.77 10.97 40.457 6.857 32 6.857c-11.886 0-21.943 8.457-24.457 19.657l30.17 30.17c10.97-2.743 19.43-12.8 19.43-24.686zm-50.286.23c0 6.4 2.514 12.57 7.314 17.37s11.2 7.314 17.37 7.314z" fill="#fff"/><path d="M32 0C14.4 0 0 14.4 0 32s14.4 32 32 32 32-14.4 32-32S49.6 0 32 0zM14.17 49.83c-4.8-4.8-7.314-11.2-7.314-17.37L31.77 57.143c-6.4-.23-12.8-2.514-17.6-7.314zm23.314 6.63L7.543 26.514C10.057 15.314 20.114 6.857 32 6.857c8.457 0 15.77 4.114 20.343 10.286l-3.43 2.97C45.03 14.857 38.857 11.43 32 11.43c-8.914 0-16.457 5.714-19.43 13.714L38.857 51.43c6.63-2.286 11.657-8 13.257-14.857h-10.97V32h16c0 11.886-8.457 21.943-19.657 24.457z" fill="#639"/>
    </StyledSvg>
);

export default SabaSVG;
