import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 1889.4 627" xmlSpace="preserve">
<path fill="#ffffff" d="M1873.4,510c-8.9,0-16-7.1-16-16c0-8.7,7.1-16,16-16c8.7,0,16,7.1,16,16C1889.4,502.7,1882.1,510,1873.4,510
    M1873.4,481.1c-7.1,0-12.9,5.8-12.9,12.9s5.8,12.9,12.9,12.9s12.9-5.8,12.9-12.9S1880.5,481.1,1873.4,481.1 M1881.5,502.4h-3.7
   l-3.2-6h-4.2v6h-3.4v-17.6h8.4c3.7,0,6,2.4,6,5.8c0,2.6-1.6,4.5-3.7,5.3L1881.5,502.4z M1875.2,493.2c1.3,0,2.6-0.8,2.6-2.6
   c0-2.1-1.1-2.6-2.6-2.6h-5.3v5.3H1875.2z M202.7,476.4h41v111.7h71.2v35.8H202.7L202.7,476.4z M357.3,550.5V550
   c0-42.3,34.2-76.8,79.7-76.8s79.1,33.9,79.1,76.2v0.5c0,42.3-34.2,76.8-79.7,76.8S357.3,592.8,357.3,550.5 M474.5,550.5V550
   c0-21.3-15.2-39.7-37.9-39.7c-22.3,0-37.3,18.1-37.3,39.4v0.5c0,21.3,15.2,39.7,37.6,39.7C459.5,589.9,474.5,571.8,474.5,550.5
    M566.3,559.2v-82.8h41.5v82c0,21.3,10.8,31.3,27.1,31.3s27.1-9.7,27.1-30.2v-83.1h41.5v81.8c0,47.6-27.1,68.4-69.1,68.4
   C592.6,626.5,566.3,605.4,566.3,559.2 M766.1,476.4h56.8c52.6,0,83.3,30.2,83.3,72.8v0.5c0,42.6-31,74.1-84.1,74.1h-56L766.1,476.4z
    M823.6,587.6c24.4,0,40.7-13.4,40.7-37.3v-0.5c0-23.7-16.3-37.3-40.7-37.3h-16.6v74.9h16.6V587.6z M965.6,476.4h118v35.8h-77.3
   v25.2h69.9v33.9h-69.9v52.6h-40.7V476.4z M1140.4,476.4h40.7v111.7h71.5v35.8h-112.3V476.4z M1359.4,475.3h39.4l62.8,148.5h-43.9
   l-10.8-26.3h-56.8l-10.5,26.3h-42.9L1359.4,475.3z M1395.4,565.7l-16.3-41.8l-16.6,41.8H1395.4z M1514.3,476.4h69.7
   c22.6,0,38.1,5.8,48.1,16c8.7,8.4,13.1,19.7,13.1,34.4v0.5c0,22.6-12.1,37.6-30.2,45.2l35.2,51.5h-47.3l-29.7-44.7h-17.9v44.7h-41
   L1514.3,476.4z M1582.1,547.1c13.9,0,21.8-6.8,21.8-17.4v-0.5c0-11.6-8.4-17.4-22.1-17.4H1555v35.2L1582.1,547.1z M1704.1,476.4
   h118.6v34.7h-78.1v22.3h70.7v32.3h-70.7v23.4h79.1v34.7h-119.6L1704.1,476.4z M113.3,567.8c-5.8,12.9-17.9,22.1-33.7,22.1
   c-22.3,0-37.6-18.7-37.6-39.7v-0.5c0-21.3,15-39.4,37.3-39.4c16.8,0,29.7,10.3,35,24.4h43.1c-6.8-35.2-37.9-61.3-77.8-61.3
   C34.2,473.5,0,507.9,0,550.2v0.5C0,593.1,33.7,627,79.1,627c38.9,0,69.4-25.2,77.3-58.9L113.3,567.8z"/>
<path fill="#FFFFFF" d="M1774.3,241.1l-112.8-64.7L1642,168l-461.4,3.2v234.2h593.6L1774.3,241.1z"/>
<path fill="#F38020" d="M1568.9,383.8c5.5-18.9,3.4-36.3-5.8-49.2c-8.4-11.8-22.6-18.7-39.7-19.5l-323.6-4.2c-2.1,0-3.9-1.1-5-2.6
   c-1.1-1.6-1.3-3.7-0.8-5.8c1.1-3.2,4.2-5.5,7.6-5.8l326.5-4.2c38.6-1.8,80.7-33.1,95.4-71.5l18.7-48.6c0.8-2.1,1.1-4.2,0.5-6.3
   C1621.8,71,1536.9,0,1435.4,0c-93.6,0-173,60.5-201.4,144.3c-18.4-13.7-41.8-21-67-18.7c-45,4.5-81,40.5-85.4,85.4
   c-1.1,11.6-0.3,22.9,2.4,33.4c-73.3,2.1-132,62-132,135.9c0,6.6,0.5,13.1,1.3,19.7c0.5,3.2,3.2,5.5,6.3,5.5h597.3
   c3.4,0,6.6-2.4,7.6-5.8L1568.9,383.8z"/>
<path fill="#FAAE40" d="M1672,175.9c-2.9,0-6,0-8.9,0.3c-2.1,0-3.9,1.6-4.7,3.7l-12.6,43.9c-5.5,18.9-3.4,36.3,5.8,49.2
   c8.4,11.8,22.6,18.7,39.7,19.5l68.9,4.2c2.1,0,3.9,1.1,5,2.6c1.1,1.6,1.3,3.9,0.8,5.8c-1.1,3.2-4.2,5.5-7.6,5.8l-71.8,4.2
   c-38.9,1.8-80.7,33.1-95.4,71.5l-5.3,13.4c-1.1,2.6,0.8,5.3,3.7,5.3H1836c2.9,0,5.5-1.8,6.3-4.7c4.2-15.2,6.6-31.3,6.6-47.8
   C1848.9,255.3,1769.5,175.9,1672,175.9"/></StyledSvg>
);

export default SabaSVG;
