import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import Typescript from "~/assets/svg/typescript";
import Gatsby from "~/assets/svg/gatsby";
import Html from "~/assets/svg/html";
import Css from "~/assets/svg/css";
import Node from "~/assets/svg/node";
import Javascript from "~/assets/gif/js.gif";
import ReactGif from "~/assets/gif/react.gif";
import Github from "~/assets/gif/github.gif";
import Styledcomponent from "~/assets/svg/styledComponents";
import Next from "~/assets/svg/next";
import Netlify from "~/assets/svg/netlify";
import Npm from "~/assets/svg/npm";
import Prettier from "~/assets/svg/prettier";
import VS from "~/assets/svg/vs";
import Git from "~/assets/svg/git";
import SourceTree from "~/assets/svg/sourcetree";
import Webpack from "~/assets/svg/webpack";
import Webstorm from "~/assets/svg/webstorm";
import Xml from "~/assets/svg/xml";
import Livestream from "~/assets/svg/livestream";
import Eslink from "~/assets/svg/eslint";
import Leaflet from "~/assets/svg/leaflet";
import Json from "~/assets/svg/json";
import Heroku from "~/assets/svg/heroku";
import Googlecloud from "~/assets/svg/googlecloud";
import GihBash from "~/assets/svg/gitbash";
import Eslint from "~/assets/svg/eslint";
import Cloudflare from "~/assets/svg/cloudflare";
import Aws from "~/assets/svg/aws";
import Xd from "~/assets/svg/xd";
import Imgix from "~/assets/svg/imgix";
import Bitbucket from "~/assets/svg/bitbucket";
import Figma from "~/assets/svg/figma";
import delivery from "~/assets/mp4/delivery.mp4"
import design from "~/assets/mp4/design.mp4"
import agile from "~/assets/mp4/agile.mp4"
import pm from "~/assets/mp4/pm.mp4"
import lastesttech from "~/assets/mp4/latesttech.mp4"
import latesttech2 from "~/assets/mp4/latesttech2.mp4"
import productivity from "~/assets/mp4/productive.mp4"
import teamwork from "~/assets/mp4/teamwork.mp4"
import seo from "~/assets/mp4/seo.mp4"

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            hi
            iam
            webdev
            product
            desc1
            desc2
            desc3
            desc4
            desc5
          }
    }
`;
const Img = styled.img`
  width: 20rem;
  height: 20rem;
`;

const Text = styled.p`
    padding-top: 15rem;
    font-size: 2.7rem;
    text-align: center;
    font-family: 'Cinzel', serif;
    font-weight: 700;
`;

const MainText = styled.p`
    font-size: 3em;
    color: #d6d4d4;
    font-family: "Roboto";
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    line-height: 4.25rem;
    padding: 0 0 0 4rem;
    @media (max-width: 1250px) {
      padding: 0;
    }
`;
const MainText2 = styled(MainText)`
    font-size: 1em;
    line-height: 2rem;
    border: 2px solid #6c63ff;
    border-radius: 10px;
    text-align: center;
    letter-spacing: 2px;
    padding: 1rem 0;
    margin-bottom: 2rem;
`;
const MainText3 = styled(MainText)`
    font-size: 1em;
    line-height: 2rem;
    letter-spacing: 2px;
    padding: 2rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    padding-bottom: 10rem;
`;

const GIF = styled.img`
    height: 5rem;
    width: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;

const Card = styled.div`
    width: 30rem;
    height: 25rem;
    background-color: #6de6ac;
    position: relative;
    border-radius: 50px;
    margin-top: 10rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @media(max-width: 650px) {
        width: 100%
    }
`;
const Card1 = styled(Card)`
    background-color: #e6e26d;
`;
const Card2 = styled(Card)`
    background-color: #e6a36d;
`;
const Card3 = styled(Card)`
    background-color: #b66de6;
`;
const Card4 = styled(Card)`
    background-color: #6de6c0;
`;
const Card5 = styled(Card)`
    background-color: #e66da1;
`;
const Card6 = styled(Card)`
    background-color: #a1e66d;
`;
const Card7 = styled(Card)`
    background-color: #6dcae6;
`;
const Video = styled.video`
    width: 26rem;
    height: 20rem;
    position: absolute;
    object-fit: cover;
    z-index: 2;
    transition: all 2s;
    margin: 0 auto;
    top: -7rem;
    left: 0;
    right: 0;
    border-radius: 50px;
    @media(max-width: 650px) {
        width: 80%
    }
`;
const IndexPage: React.FunctionComponent<PageProps> = (props) => {
    const strings = useStringsContext();

    return (
        <>
            <SEO
                lang={props.pageContext.language}
                title="Saba Hossein - Skill"
                description="Full Stack Web Developer with over 9 years of experience and with a large set of supporting skills. Experienced in Typescript, Javascript, React, Gatsby, Adobe XD, UX/UI Design, Node.js, HTML, CSS, Styled Components, React Spring, AWS, Imgix, HeadLess CMS, and many more."
                keywords={["saba"]}
                url={props.location.pathname}
            />
            <MainText3>Technologies Worked on</MainText3>
            <Container>
                <Typescript />
                <Gatsby />
                <GIF src={Javascript} />
                <GIF src={ReactGif} />
                <GIF src={Github} />
                <Html />
                <Node />
                <Css />
                <Styledcomponent />
                <Next />
                <Netlify />
                <Npm />
                <Prettier />
                <VS />
                <Git />
                <SourceTree />
                <Webpack />
                <Webstorm />
                <Xml />
                <Livestream />
                <Eslink />
                <Leaflet />
                <Json />
                <Heroku />
                <Googlecloud />
                <GihBash />
                <Eslint />
                <Cloudflare />
                <Aws />
                <Xd />
                <Imgix />
                <Bitbucket />
                <Figma />
                <SourceTree />

            </Container>
            <MainText3 style={{marginBottom: "-10rem"}} >Project Management Skills</MainText3>
            <Container style={{paddingTop: "10rem", gap:"5rem"}}>
                
                <Card>
                    <Video loop autoPlay muted playsInline >
                    <source src={delivery} type="video/mp4"/>
                    </Video>
                    <Text>End to End Delivery</Text>
                </Card>
                <Card1>
                <Video loop autoPlay muted playsInline >
                    <source src={teamwork} type="video/mp4"/>
                    </Video>
                    <Text>Teamwork</Text>
                </Card1>
                <Card2>
                <Video loop autoPlay muted playsInline >
                    <source src={productivity} type="video/mp4"/>
                    </Video>
                    <Text>Productivity</Text>
                </Card2>
                <Card3>
                <Video loop autoPlay muted playsInline >
                    <source src={latesttech2} type="video/mp4"/>
                    </Video>
                    <Text>Worked on Latest Tech</Text>
                </Card3>
                <Card4>
                <Video loop autoPlay muted playsInline >
                    <source src={pm} type="video/mp4"/>
                    </Video>
                    <Text>Project Management</Text>
                </Card4>
                <Card5>
                <Video loop autoPlay muted playsInline >
                    <source src={agile} type="video/mp4"/>
                    </Video>
                    <Text>Agile Methodologies</Text>
                </Card5>
                <Card6>
                    <Video loop autoPlay muted playsInline >
                    <source src={seo} type="video/mp4"/>
                    </Video>
                    <Text>Accessibility and SEO</Text>
                </Card6>
                <Card7>
                    <Video loop autoPlay muted playsInline >
                    <source src={lastesttech} type="video/mp4"/>
                    </Video>
                    <Text>Implemented Latest Stack</Text>
                </Card7>
            </Container>
        </>
    )
}

export default IndexPage
