import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="320" height="128" viewBox="0 0 320 128">
    <defs>
      <linearGradient id="ix-gradient" x1="0" y1="0" x2="0" y2="100%">
        <stop offset="0" stop-color="#ff6633"/>
        <stop offset="100%" stop-color="#d84a2e"/>
      </linearGradient>
    </defs>
    <path fill="url(#ix-gradient)" d="M312,108h-96c-4.4,0-8-3.6-8-8V20c0-4.4,3.6-8,8-8h96c4.4,0,8,3.6,8,8v80C320,104.4,316.4,108,312,108z"/>
    <path fill="#ffffff" d="M226.7,94c-3.7,0-6.7-3-6.7-6.8V32.8c0-3.8,3-6.8,6.7-6.8c3.7,0,6.7,3,6.7,6.8v54.4 C233.4,91,230.4,94,226.7,94z"/>
    <path fill="#ffffff" d="M286.8,60l19.6-22.7c2.4-2.8,2.2-7.1-0.6-9.6c-2.8-2.5-7-2.2-9.4,0.6l-18.4,21.4l-18.4-21.4 c-2.4-2.8-6.7-3.1-9.4-0.6c-2.8,2.5-3.1,6.8-0.6,9.6L269,60l-19.6,22.7c-2.4,2.8-2.2,7.1,0.6,9.6c1.3,1.1,2.8,1.7,4.4,1.7 c1.9,0,3.7-0.8,5-2.3l18.4-21.4l18.4,21.4c1.3,1.5,3.2,2.3,5,2.3c1.6,0,3.1-0.6,4.4-1.7c2.8-2.5,3.1-6.8,0.6-9.6L286.8,60z"/>
    <path fill="#475f72" d="M8.4,77.5V43.8H0V33.3h18.9v44.2h8.4V88H0V77.5H8.4z M18.9,14.3c0,3.5-2.8,6.3-6.3,6.3s-6.3-2.8-6.3-6.3 S9.1,8,12.6,8S18.9,10.8,18.9,14.3z"/>
    <path fill="#475f72" d="M41.7,77.5V43.8h-8.4V33.3H53v6.3c6.3-5.1,12.9-7.4,19-7.4c6.1,0,10.3,2.8,12.7,8.5c7-5.7,13.5-8.5,19.5-8.5 c3.5,0,6.3,0.8,8.6,2.3c2.2,1.6,3.7,3.5,4.5,5.9c0.8,2.4,1.2,5.9,1.2,10.6v26.5h9.5V88h-20.1V52.9c0-3.2-0.1-5.4-0.4-6.7 c-0.3-1.3-1-2.4-2.1-3.2c-1.1-0.8-2.5-1.3-4.1-1.3c-4.5,0-9.6,2.3-15.4,7v28.7h8.3V88H75.5V51.9c0-3.6-0.5-6.2-1.5-7.8 c-1-1.6-2.7-2.4-5.2-2.4c-4,0-10.3,1.6-16.6,6.3v29.5h10.6V88H33.3V77.5H41.7z"/>
    <path fill="#475f72" d="M196,33.3v10.5h-9.1l-0.1,39.4c0,5.5-0.3,9.6-0.8,12.2c-0.6,2.7-1.9,5.4-4,8.1c-2.1,2.8-5.3,5.1-9.6,7 c-4.3,1.9-9,2.8-14.2,2.8c-7.9,0-15-2.4-21.2-7.3l6.9-7.8c4.7,3.7,9.7,5.5,15.2,5.5c3,0,5.8-0.6,8.4-1.8c2.6-1.2,4.5-2.6,5.8-4.3 c1.3-1.7,2.1-3.3,2.5-4.8c0.4-1.5,0.6-4.1,0.6-7.7v-5c-1.7,2.1-4.3,4.2-7.8,6.1c-3.5,1.9-7.4,2.9-11.6,2.9c-7.7,0-14-2.7-18.8-8.1 c-4.8-5.4-7.3-12.1-7.3-20c0-8.6,2.7-15.6,8-20.9c5.3-5.3,11.5-8,18.5-8c7.1,0,14.2,3.8,19.8,9.6v-8.4H196z M141.9,60.8 c0,5.5,1.6,10,4.7,13.5c3.2,3.5,7.2,5.2,12.1,5.2c4.8,0,8.9-1.8,12.4-5.3c3.5-3.5,5.2-8,5.2-13.6c0-5.6-1.7-10.1-5.2-13.5 c-3.5-3.4-7.5-5.1-12.1-5.1c-4.6,0-8.6,1.7-12,5C143.7,50.6,141.9,55.1,141.9,60.8z"/></StyledSvg>
);

export default SabaSVG;
