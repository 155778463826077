import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg width="2500" height="2500" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M153.094 165.679l-62.785-13.12a14.631 14.631 0 0 1-.876 1.475l57.157 83.378 3.448-3.444 9.724-60.47a11.458 11.458 0 0 1-6.668-7.82zM130.19 83.605c-2.352 3.608-6.412 5.996-11.036 5.996-.712 0-1.404-.072-2.084-.18l-28.633 44.801 76.498-32.92c-.024-.308-.092-.596-.092-.912 0-.644.088-1.268.192-1.884l-34.845-14.9zm2.08-8.164l37.065 15.848a11.447 11.447 0 0 1 3.976-2.008l6.092-37.88-23.209-23.209L130.07 69.07a13.013 13.013 0 0 1 2.2 6.372zm77.665 6.492l-23.26-23.264-5.056 31.408a11.628 11.628 0 0 1 3.396 2.584l24.92-10.728zm-55.729 75.473a11.355 11.355 0 0 1 9.089-5.844l6.68-41.548c-.524-.348-.992-.764-1.452-1.192l-76.938 33.112c.196.804.332 1.624.396 2.476l62.225 12.996zm62.065-69.133l-26.912 11.58 66.401 28.389.24-.24-39.729-39.729zm-48.092 85.35l-8.088 50.284 46.964-46.964-33.828-7.064a11.366 11.366 0 0 1-5.048 3.744zm-91.286-12.928c-1.7 0-3.328-.292-4.852-.809l-15.6 24.413-6.028-6.024 14.776-23.12a16.066 16.066 0 0 1-1.02-1.417l-26.768 11.525-6.345-6.345 29.3-12.612L7.385 135.25.14 128.002l2.292-2.292 61.085 12.752a14.981 14.981 0 0 1 2.668-3.616L36.537 91.601l5.988-5.988L73.5 130.798a15.283 15.283 0 0 1 3.392-.396c1.308 0 2.576.184 3.788.496l28.985-45.353a13.126 13.126 0 0 1-3.676-9.116c0-1.04.132-2.048.36-3.016l-36.16-15.46 6.343-6.344 34.26 14.652a13.12 13.12 0 0 1 12.653-2.268l26.724-41.825L128.002 0 0 128.002l128.002 128.002 12.596-12.596-57.585-84.01a15.117 15.117 0 0 1-6.12 1.297zm101.23-48.89l-6.796 42.237a11.359 11.359 0 0 1 4.196 7.813l38.572 8.052 35.32-35.32-63.752-27.257a11.508 11.508 0 0 1-7.54 4.476z" fill="#25C7B7"/></StyledSvg>
);

export default SabaSVG;
