import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg height="2500" viewBox="39.97 26.75 208.06 234.5" width="2023" xmlns="http://www.w3.org/2000/svg"><path d="m227.82 69.69v191.56h-167.64v-234.5h131.48z" fill="#e0e0e0" stroke="#020202" stroke-miterlimit="10" stroke-width="10"/><path d="m39.97 129.78h208.06v92.3h-208.06z" fill="#ffe083" stroke="#020202" stroke-miterlimit="10" stroke-width="10"/><path d="m186.16 28.5s-9.48 57.43 40.66 44.18z" fill="#ffe083"/><g fill="#020202"><path d="m183.17 27.5 43.4 48.89 3-5.48-37.91-44.16z"/><path d="m223.58 73.02v.62l.75.01v-1l-.75.15z"/><rect height="7.23" rx="3.62" width="83.07" x="87.37" y="64.67"/><rect height="6.99" rx="3.49" width="113.26" x="87.37" y="83.88"/><rect height="7.48" rx="3.74" width="113.26" x="87.37" y="102.34"/></g><path d="m186.66 30s-10 54.39 37.67 43.66" fill="none" stroke="#020202" stroke-miterlimit="10" stroke-width="10"/><g fill="#020202"><path d="m81.38 154.23h10l28.67 43.16h-9.45z"/><path d="m108.83 154.23-28.84 43.16h10.88l29.18-43.16zm16.7 0h12.65l8.56 30.43 7.63-30.43h13.15v43.16h-7.59l-.21-34.68-8.39 34.68h-9.1l-8.46-33.68-.15 33.68h-8.09zm51.61 0h8.6v36.28h22.01v6.88h-30.61z"/></g></StyledSvg>
);

export default SabaSVG;
