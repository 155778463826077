import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg viewBox="-2.14721867003227 -3.552713678800501e-15 61.184977014970144 71.72999999999999" xmlns="http://www.w3.org/2000/svg" width="2207" height="2500"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="42.66" x2="42.66" y1="50.35" y2="20.14"><stop offset=".18" stop-color="#0052cc"/><stop offset="1" stop-color="#2684ff"/></linearGradient><path d="M56.88 28.44A28.44 28.44 0 0 0 28.44 0a28.43 28.43 0 0 0-8.8 55.48v14.25a2 2 0 0 0 2 2h13.5a2 2 0 0 0 2-2V55.5A28.45 28.45 0 0 0 56.9 28.43zm-28.44 9.6a9.6 9.6 0 1 1 0-19.22 9.6 9.6 0 0 1 0 19.23z" fill="#2684ff"/><path d="M28.44 0v18.83a9.6 9.6 0 0 1 0 19.23 8.8 8.8 0 0 1 8.8 8.8v8.64A28.44 28.44 0 0 0 28.43 0z" fill="url(#a)"/></StyledSvg>
);

export default SabaSVG;
