import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg width="2162" height="2500" viewBox="0 0 256 296" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet"><defs><radialGradient fx="50%" fy="50%" id="a"><stop stop-color="#93B4E7" offset="0%"/><stop stop-color="#B9D1F8" offset="59.273%"/><stop stop-color="#9CB6E0" offset="100%"/></radialGradient></defs><path d="M128.032.456L.298 74.203v147.495l127.734 73.748 127.735-73.748V74.203L128.032.456z" fill="url(#a)"/><path d="M128.034 75.263L64.69 111.834v73.144l63.344 36.572 63.344-36.572v-73.144l-63.344-36.571z" fill="#6F95DB"/><path d="M128 0L0 74.25l128 69.693 128-69.238L128 0zM128 153.509L0 221.38l128 73.793 127.544-74.249L128 153.51z" fill="#FFF" fill-opacity=".1"/></StyledSvg>
);

export default SabaSVG;
