import React from "react";

import styled from "styled-components";

const StyledSvg = styled.svg`
    width: 5rem;
    height: 5rem;
    transition: 1s all ease-in-out;
    :hover {
        width: 6rem;
        height: 6rem;
    }
`;
declare var fill: any
const SabaSVG: React.FC = () => (
    <StyledSvg width="256px" height="256px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
    <defs>
        <linearGradient x1="41.7030965%" y1="1.92488263%" x2="71.8761384%" y2="95.2347418%" id="linearGradient-1">
            <stop stop-color="#00CDD7" offset="28%"></stop>
            <stop stop-color="#2086D7" offset="94%"></stop>
        </linearGradient>
        <linearGradient x1="22.4892241%" y1="15.1271186%" x2="82.8771552%" y2="84.7627119%" id="linearGradient-2">
            <stop stop-color="#FFF045" offset="14%"></stop>
            <stop stop-color="#00CDD7" offset="37%"></stop>
        </linearGradient>
        <linearGradient x1="11171%" y1="-27691.7984%" x2="11907%" y2="-28759.6256%" id="linearGradient-3">
            <stop stop-color="#00CDD7" offset="28%"></stop>
            <stop stop-color="#2086D7" offset="94%"></stop>
        </linearGradient>
    </defs>
	<g>
		<polygon fill="url(#linearGradient-1)" points="34.4 231.2 0 26.8 64 0.4 104.4 24.4 142 4.4 219.6 34.4 176 256"></polygon>
		<polygon fill="url(#linearGradient-2)" points="256 86.8 222.8 5.2 163.2 0 70.4 88.8 95.2 203.6 142 236 256 168.4 228 116"></polygon>
		<polygon fill="url(#linearGradient-3)" points="204.8 74.4 228 116 256 86.8 235.6 36"></polygon>
		<rect fill="#000000" x="48" y="48" width="160" height="160"></rect>
		<path d="M63.2,178 L123.2,178 L123.2,188 L63.2,188 L63.2,178 Z M141.6,125.6 L150,115.2 C156,120 162,123.2 169.6,123.2 C175.6,123.2 179.2,120.8 179.2,116.8 C179.2,113.2 176.8,111.2 166,108.4 C152.8,104.8 144.4,101.2 144.4,88 L144.4,87.6 C144.4,75.6 154,67.6 167.2,67.6 C176.04879,67.5627811 184.649333,70.5236236 191.6,76 L184,87.2 C179.170815,83.4261245 173.316089,81.1957528 167.2,80.8 C162,80.8 158.8,83.2 158.8,86.8 C158.8,91.2 161.6,92.8 172.8,95.6 C186,99.2 193.6,104 193.6,115.6 C193.6,128.8 183.6,136.4 169.6,136.4 C159.323641,136.02223 149.468301,132.220884 141.6,125.6 Z M128.8,68.8 L118.8,107.2 L107.6,68.8 L96.4,68.8 L85.2,107.2 L75.2,68.8 L59.6,68.8 L78.8,135.2 L91.2,135.2 L102,96.8 L112.8,135.2 L125.2,135.2 L144.4,68.8 L128.8,68.8 Z" fill="#FFFFFF"></path>
	</g>
    </StyledSvg>
);

export default SabaSVG;
